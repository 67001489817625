import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import AboutScreen from './screens/AboutScreen';
import BooksScreen from './screens/BooksScreen';
import NewsScreen from './screens/NewsScreen';
import ValuesScreen from './screens/ValuesScreen';
import ProjectsScreen from './screens/ProjectsScreen';
import ContactsScreen from './screens/ContactsScreen';
import KitapqumarScreen from './screens/KitapqumarScreen.js';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/books" element={<BooksScreen />} />
            <Route path="/news" element={<NewsScreen />} />
            <Route path="/values" element={<ValuesScreen />} />
            <Route path="/projects" element={<ProjectsScreen />} />
            <Route path="/contacts" element={<ContactsScreen />} />
            <Route path="/kitapqumar" element={<KitapqumarScreen />} />
        </Routes>
    );
}

export default AppRoutes;