import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import BooksSection from "../components/BooksSection";

const BooksScreen = () => {
    return (
        <div>
            <NavBar />
            <main>
                <BooksSection />
            </main>
            <Footer />
        </div>
    );
};

export default BooksScreen;