import React from "react";
import "../styles/TeamSection.css";

import image1 from '../assets/images/photo/1.png';
import image2 from '../assets/images/photo/2.png';
import image3 from '../assets/images/photo/3.png';
import image4 from '../assets/images/photo/4.png';
import image5 from '../assets/images/photo/5.png';
import image6 from '../assets/images/photo/6.png';
import image7 from '../assets/images/photo/7.png';

const TeamSection = () => {
    const teamMembers = [
        { name: "Абзал Құспан", role: "Жоба авторы", image: image1 },
        { name: "Санжар Керімбай", role: "Жоба жетекшісі", image: image2 },
        { name: "Әділбек Нәби", role: "Автор", image: image3 },
        { name: "Сәкен Нұрқабекұлы", role: "Автор", image: image4 },
        { name: "Сейілбек Мырзабай", role: "Автор", image: image5 },
        { name: "Бағдат Құдияр", role: "Атқарушы директор", image: image6 },
        { name: "Арман Ғинаятұлы", role: "Суретші, дизайнер", image: image7 },
    ];

    return (
        <section className="team-section">
            <h2>Біздің команда</h2>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div className="team-card" key={index}>
                        <div className="team-image">
                            <img src={member.image} alt={member.name} />
                        </div>
                        <div className="team-info">
                            <h3>{member.name}</h3>
                            <p>{member.role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TeamSection;