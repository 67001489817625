import React from "react";
import "../styles/contact_section.css";

const ContactSection = () => {
    return (
        <section className="contact_section">
            <div className="contact_container">
                <h2 className="contact_heading">Бізбен хабарласыңыз</h2>
                <div className="contact_details">
                    <div className="contact_info">
                        <h3 className="contact_subheading">Мекенжай</h3>
                        <p className="contact_text">​Alem City ​Шағын аудан Шұғыла, 340/3 к17 ​цокольный қабат Шұғыла, Наурызбай, Алматы, 050006</p>

                        <h3 className="contact_subheading">Телефон</h3>
                        <p className="contact_text">+7 (707) 327 5665</p>

                        <h3 className="contact_subheading">Бізге жазылыңыз</h3>
                        <div className="social_links">
                            <a href="https://t.me/OtbasyBooksBot" target="_blank" rel="noopener noreferrer" className="social_link">Telegram</a>
                            <a href="https://www.youtube.com/c/OtbasyHrestomatiasy" target="_blank" rel="noopener noreferrer" className="social_link">Youtube</a>
                            <a href="https://instagram.com/otbasyhrestomatiasy?igshid=x4qb0whyrb9j" target="_blank" rel="noopener noreferrer" className="social_link">Instagram</a>
                        </div>
                    </div>

                    <div className="contact_map">
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d781.1776017110458!2d76.78950174395494!3d43.20724730831031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2skz!4v1737352458965!5m2!1sen!2skz"
                            width="100%"
                            height="300"
                            style={{ borderRadius: 10 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;