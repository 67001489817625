import React, { useState } from "react";
import "../styles/Header.css";
import logo from '../logo.svg';
import { useNavigate } from 'react-router-dom';
import menu_icon from '../assets/icons/menu.svg';
import close_icon from '../assets/icons/close.svg';
import RightNav from './RightNav';

const NavBar = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo">
                    <img onClick={() => navigate('/')} src={logo} alt="Отбасы Хрестоматиясы" />
                </div>
                <nav className="nav">
                    <a href="/about">Біз туралы</a>
                    <a href="/news">Жаңалықтар</a>
                    <a href="/values">Құндылықтар</a>
                    <a href="/projects">Жобалар</a>
                    <a href="/contacts">Контактілер</a>
                </nav>
                <button onClick={() => navigate('/books')} className="button header_books_button">Кітаптар</button>
                <button onClick={handleMenuToggle} className="nav_button">
                    <img src={isMenuOpen ? close_icon : menu_icon} alt="Menu Icon" />
                </button>
            </div>
            {isMenuOpen && <RightNav onClose={handleMenuToggle} />}
        </header>
    );
};

export default NavBar;