import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../styles/BookList.css";
import books from "../data/books.json";

const getBookLink = (book) => {
  if (book.includes("Әділ патша кітабынан")) {
    return "https://kaspi.kz/shop/p/ker-mbai-s-d-l-patsha-103350250/?c=750000000";
  } else if (book.includes("Мың сұрақ кітабы")) {
    return "https://kaspi.kz/shop/p/ker-mbai-s-n-bi-t-legen-m-n-r-abek-ly-s-my-s-ra--111081628/?c=750000000";
  } else if (book.includes("Отбасындағы 30 сабақ кітабынан")) {
    return "https://kaspi.kz/shop/p/-aldybek-yzy-n-bi-otbasynda-y-30-saba-zh-mys-d-pter--111400373/?c=750000000";
  } else if (book.includes("Ромен Роллан")) {
    return "https://kaspi.kz/shop/p/romen-r-zhan-kristof-111400138/?c=750000000";
  } else if (book.includes("Л.Н.Толстой")) {
    return "https://kaspi.kz/shop/p/tolstoi-l-n-balalar-sh-n-104531819/?c=750000000";
  } else if (book.includes("Ағайынды Гриммдердің ертегілері")) {
    return "https://kaspi.kz/shop/p/vil-gel-m-g-jakob-g-a-aiyndy-grimmderd-erteg-ler--111399000/?c=750000000";
  } else if (book.includes("Авторы Дхан Мукержи")) {
    return "https://kaspi.kz/shop/p/dhan-mukerzhi-hari-men-k-ri-130875852/?c=750000000";
  } else if (book.includes("Авторы: Э.Распе")) {
    return "https://kaspi.kz/shop/p/raspe-e-m-nhauzenn-basynan-keshken-hikajasy-111399759/?c=750000000";
  } else if (book.includes("Авторы: Фатыма Гүнер")) {
    return "https://kaspi.kz/shop/p/guner-f-k-shkentai-suretsh--105120033/?c=750000000";
  } else if (book.includes("Авторы: Хушанг Муради Кермани")) {
    return "https://kaspi.kz/shop/p/bala-m-zhitt-hikajalary-105495463/?c=750000000";
  } else if (book.includes("Авторы: Корней Чуковский")) {
    return "https://kaspi.kz/shop/p/chukovskii-k-doktor-aibolit-112141335/?c=750000000";
  } else if (book.includes("Авторы: Э.Сетон Томпсон.")) {
    return "https://kaspi.kz/shop/p/seton-tompson-e-zhyry-la--111399142/?c=750000000";
  } else if (book.includes("Авторы: Уида")) {
    return "https://kaspi.kz/shop/p/dala-109147405/?c=750000000";
  } else if (book.includes("Муму. Авторы: И.С.Тургенов")) {
    return "https://kaspi.kz/shop/p/turgenev-i-s-mumu-111557607/?c=750000000";
  } else if (book.includes("Жәк Лондон")) {
    return "https://kaspi.kz/shop/p/london-zh-as-yr-kish-turaly-a-yz-111399635/?c=750000000";
  } else if (book.includes("Асыранды аққу. Авторы: Д.Мамин-Сибиряк")) {
    return "https://kaspi.kz/shop/p/mamin-sibirjak-d-asyrandy-a-u-111399222/?c=750000000";
  } else if (book.includes("Емеля аңшы. Авторы: Д.Мамин-Сибиряк")) {
    return "https://kaspi.kz/shop/p/mamin-sibirjak-d-asyrandy-a-u-111399222/?c=750000000";
  } else if (book.includes("Кім жыртқыш? Авторы: Д.Мамин-Сибиряк")) {
    return "https://kaspi.kz/shop/p/mamin-sibirjak-d-asyrandy-a-u-111399222/?c=750000000";
  } else if (book.includes("Сұр мойын. Авторы: Д.Мамин-Сибиряк")) {
    return "https://kaspi.kz/shop/p/mamin-sibirjak-d-asyrandy-a-u-111399222/?c=750000000";
  } else if (book.includes("Нелло мен Патраш. Авторы: Уида")) {
    return "https://kaspi.kz/shop/p/uida-marija-luiza-de-la-ramen-nello-men-patrash-111399797/?c=750000000";
  } else if (book.includes("Бөдене. Авторы: И.С.Тургенов")) {
    return "https://kaspi.kz/shop/p/turgenev-i-s-b-dene-bir-k-shal-yn-111399302/?c=750000000";
  } else if (book.includes("Бирүк. Авторы: И.С.Тургенов")) {
    return "https://kaspi.kz/shop/p/turgenev-i-s-b-dene-bir-k-shal-yn-111399302/?c=750000000";
  } else if (book.includes("Шалғын. Авторы: И.С.Тургенов")) {
    return "https://kaspi.kz/shop/p/turgenev-i-s-b-dene-bir-k-shal-yn-111399302/?c=750000000";
  } else if (book.includes("Авторы: Э.Сетон Томпсон.")) {
    return "https://kaspi.kz/shop/p/seton-tompson-e-zhyry-la--111399142/?c=750000000";
  } else if (book.includes("(Экософия немесе киелі табиғат кітабынан)")) {
    return "https://kaspi.kz/shop/p/ekosofija-nemese-kiel-tabi-at-111183036/?c=750000000";
  } else if (book.includes("(Әлдиден эпосқа дейін кітабынан)")) {
    return "https://kaspi.kz/shop/p/-ldiden-epos-a-dei-n-130269767/?c=750000000";
  } else if (book.includes("(Отбасындағы 30 сабақ кітабынан)")) {
    return "https://kaspi.kz/shop/p/-aldybek-yzy-n-bi-otbasynda-y-30-saba-zh-mys-d-pter--111400373/?c=750000000";
  } else if (book.includes("Сандыбадтың жеті саяхаты")) {
    return "https://kaspi.kz/shop/p/-bd-adyrov-m-hamedhanov-sandybadty-zhet-sajahaty-112294296/?c=750000000";
  } else if (book.includes("(Бір беттік кітап)")) {
    return "https://kaspi.kz/shop/p/me-di-mirazimi-b-r-bett-k-k-tap-132113252/?c=750000000";
  } else if (book.includes("Авторы: Назиля Карадбейлі")) {
    return "https://kaspi.kz/shop/p/nazilja-karadbeil-yzyl-baly--118510002/?c=750000000";
  } else if (book.includes("Сөз жинаушы қыз. Авторы: Ә.Аюбек, Н.Абзалқызы")) {
    return "https://kaspi.kz/shop/p/-iger-m-ajubek-n-rai-abzal-yzy-s-z-zhinaushy-yz-117204658/?c=750000000";
  } else if (book.includes("Билейтін әріптер. Авторы: Ә.Аюбек, А.Олжахан")) {
    return "https://kaspi.kz/shop/p/ajubek-olzhahan-a-bileit-n-r-pter-117040643/?c=750000000";
  } else if (book.includes("Кітап оқымайтын қыз. Авторы: Ә.Нәби")) {
    return "https://kaspi.kz/shop/p/-d-lbek-n-bi-k-tap-o-ymaityn-yz-113239774/?c=750000000";
  } else if (book.includes("Кітаптан қорқатын бала. Авторы: Ә.Аюбек, Н.Абзалқызы")) {
    return "https://kaspi.kz/shop/p/-iger-m-ajubek-n-rai-abzal-yzy-k-taptan-or-atyn-bala-116963854/?c=750000000";
  } else if (book.includes("Сөзді қақ, тіркесті тап! Авторы: Ә.Аюбек")) {
    return "https://kaspi.kz/shop/p/-iger-m-ajubek-asylzat-a-zamhan-yzy-s-zd-a-t-rkest-tap--118510024/?c=750000000";
  } else if (book.includes("Ақылбас-экологиялық әліппе.")) {
    return "https://kaspi.kz/shop/p/ajubek-a-ylbas-117040638/?c=750000000";
  } else if (book.includes("Әдептілік әліппесі. Авторы:Мұзафар Әлімбаев")) {
    return "https://kaspi.kz/shop/p/m-zafar-l-mbaev-dept-l-k-l-ppes--132353330/?c=750000000";
  } else if (book.includes("(Салт дәстүр сөйлейді кітабынан)")) {
    return "https://kaspi.kz/shop/p/ker-mbai-s-n-bi-t-legen-m-bopai-ly-b-tasbolat-a-salt-d-st-r-s-ileid--111071054/?c=750000000";
  } else if (book.includes("(Қабуснама кітабынан)")) {
    return "https://kaspi.kz/shop/p/-d-lbek-n-bi-abusnama-109773859/?c=750000000";
  } else if (book.includes("Ер Едіге")) {
    return "https://kaspi.kz/shop/p/s-ken-n-r-abek-ly-er-ed-ge-135092406/?c=750000000";
  } else if (book.includes("(Мың сұрақ кітабынан)")) {
    return "https://kaspi.kz/shop/p/ker-mbai-s-n-bi-t-legen-m-n-r-abek-ly-s-my-s-ra--111081628/?c=750000000";
  }
  return null;
};

const BookList = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="booklist-container">
      <h1 className="booklist-title">6-10 жас арасындағы балаларға арналған «Кітапқұмар» байқауы</h1>
      <div className="accordion-list">
        {books.map((book, index) => {
          const link = getBookLink(book);
          return (
            <div key={index} className="accordion-item">
              <motion.div
                className="accordion-header"
                onClick={() => toggleAccordion(index)}
                whileHover={{ scale: 1.05 }}
              >
                {link ? (
                  <a href={link} target="_blank" rel="noopener noreferrer" className="book-title">
                    {index + 1}. {typeof book === 'object' ? book.title : book}
                  </a>
                ) : (
                  <span>{book}</span>
                )}
                <img className="kaspi_icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Kaspi.kz_logo.svg/180px-Kaspi.kz_logo.svg.png?20221012054031" alt="kaspi" />
              </motion.div>
              {/* <AnimatePresence>
                {openIndex === index && (
                  <motion.div
                    className="accordion-content"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p>Бұл кітап туралы қосымша ақпарат...</p>
                  </motion.div>
                )}
              </AnimatePresence> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookList;