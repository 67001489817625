import React from "react";
import "../styles/Footer.css";

const Footer = () => {
    const links = [
        "esti.kz",
        "Дүкендер",
        "ziyarat.kz",
        "Суреттер",
        "Құндылықтар",
        "Жаңалықтар",
        "Кітаптар",
        "Контакт",
        "Youtube",
        "Instagram",
        "Telegram",
        "Дилерлер",
        "Видеолар",
    ];

    const footerPolicies = [
        "Ережелер мен шарттар",
        "Сайт картасы",
        "Құпиялық саясаты",
        "Cookie файлдары саясаты",
    ];

    return (
        <footer className="footer">
            <div className="footer-links">
                {links.map((link, index) => (
                    <a href={`#${link}`} key={index} className="footer-link">
                        {link}
                    </a>
                ))}
            </div>
            <div className="footer-bottom">
                <p>© 2025 Отбасы хрестоматиясы</p>
                <div className="footer-policies">
                    {footerPolicies.map((policy, index) => (
                        <a href={`#${policy}`} key={index} className="footer-policy-link">
                            {policy}
                        </a>
                    ))}
                </div>
            </div>
        </footer>
    );
};

export default Footer;