import React from "react";
import "../styles/SocialSection.css";

import image1 from '../assets/images/5.png';
import image2 from '../assets/images/6.png';
import image3 from '../assets/images/7.png';

const SocialSection = () => {
    const books = [
        { image: image1 },
        { image: image2 },
        { image: image3 },
    ];

    return (
        <section className="social-section">
            <div className="social-buttons">
                <a href="#instagram" className="social-button">
                    Instagram
                </a>
                <a href="#youtube" className="social-button">
                    Youtube
                </a>
                <a href="#telegram" className="social-button">
                    Telegram
                </a>
            </div>
            <div className="book-grid">
                {books.map((book, index) => (
                    <div className="book-card" key={index}>
                        <img src={book.image} alt={book.title} className="book" />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default SocialSection;