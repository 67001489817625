import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AboutUsContent from "../components/AboutUsContent";

const AboutScreen = () => {
    return (
        <div>
            <NavBar />
            <main>
                <AboutUsContent />
            </main>
            <Footer />
        </div>
    );
};

export default AboutScreen;