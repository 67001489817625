import React from 'react';
import '../styles/AboutUsContent.css';

function AboutUsContent() {
  return (
    <div className="about-us-content">
      <h1>Біз туралы</h1>

      <section>
        <h2>2013 - Бастамасы</h2>
        <p>
          2013 жылы <strong>Отбасы хрестоматиясы</strong> сериясының алғашқы кітабы <em>“Әлдиден эпосқа дейін”</em> жарық көрді. 2014-2016 жылдары осы серияның жалғасы ретінде <em>“Салт-дәстүр сөйлейді”</em> және <em>“Сияр-Шәріп”</em> кітаптары жарияланды. Бұл еңбектер оқырмандардың көңілінен шығып, әрқайсысы 5-6 реттен қайта басылды.
        </p>
        <p>
          2017 жылы <strong>Отбасы хрестоматиясы</strong> жобасы қоғамдық қор ретінде құрылды. Осы серия аясында <em>“Логотерапия немесе өмірге ғашық болу”</em>, <em>“Яссауи феномені”</em>, <em>“Махаббаттың мәні”</em>, және басқа да кітаптар жарыққа шықты. Жалпы таралымы 150 мыңнан асты. Кітап таныстырылымдары Алматы, Астана, Шымкент және басқа да қалаларда өтті.
        </p>
      </section>

      <section>
        <h2>Ерекшеліктері</h2>
        <p>
          Қор алғаш рет қазақ оқырмандарына Виктор Франклдың <em>Логотерапия</em> идеясын таныстырды, оның еңбектерін қазақ тіліне аударды. Осы идеяларды таныстыру мақсатында <em>“Өмірге ғашық болу”</em> тақырыбында түрлі дәрістер ұйымдастырылды. Сондай-ақ <em>экософия</em> идеясы қазақ аудиториясына жеткізіліп, табиғатқа, жан-жануарларға құрмет көрсету мәдениетін қалыптастыруға үлес қосуда.
        </p>
      </section>

      <section>
        <h2>Қисса жанрын қайта тірілту</h2>
        <p>
          Қордың басты ерекшелігі – <strong>қисса жанрын</strong> дәріптеу. Бұл жанр 70 жылдық Кеңес үкіметінің цензурасына ұшырап, халық санасынан өше бастады. 2013 жылы алғаш рет қисса дәстүрін қайта тірілтіп, <em>“Қисса-сүл-әнбия”</em> еңбегін насихаттады. Қисса жанрына қатысты 5 кітап жарық көрді, оның ішінде <em>“Яссауи феномені”</em> қазақ дүниетанымындағы сакралды ұғымдарды түсіндіруге арналған.
        </p>
      </section>

      <section>
        <h2>«Білгім келеді» клубы</h2>
        <p>
          Қор жанынан «Білгім келеді» клубы құрылып, оған 100-ден астам студент мүше болды. Клуб аясында апта сайын түрлі тақырыпта тұрақты дәрістер өтіп тұрады. Қонақ ретінде Айдос Сарым, Бейсен Құранбек және басқа да тұлғалар шақырылды.
        </p>
        <p>
          2019 жылы қор «Жас отбасы» жобасын іске асырып, отбасылық құндылықтарды насихаттауға үлес қосты. 2020 жылы экологиялық жобаларды дамыту бойынша жұмыстар жүргізілді.
        </p>
      </section>
    </div>
  );
}

export default AboutUsContent;