import React from "react";
import "../styles/ProjectsSection.css";

import book from '../assets/images/book.png';
import folder from '../assets/images/folder.png';
import video from '../assets/images/video.png';

const ProjectsContent = () => {
    const cards = [
        {
            title: "esti.kz",
            description: "Кітаптар жайлы қысқаша мәлімет",
            image: book,
            link: "esti.kz",
        },
        {
            title: "ziyarat.kz",
            description: "ziyarat.kz Қазақстандағы киелі орындар жайлы мәлімет",
            image: folder,
            link: "ziyarat.kz",
        },
        {
            title: "Білгім келеді",
            description: "Білгім келеді қысқаша мәлімет",
            image: video,
            link: "#",
        },
        {
            title: "Кітап құмар",
            description: "Кітап құмар қысқаша мәлімет",
            image: video,
            link: "kitapqumar",
        },
    ];

    return (
        <section className="projects-section">
            <div className="cards-container">
                {cards.map((card, index) => (
                    <div style={{ backgroundImage: `url(${card.image})` }} className="card" key={index}>
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>
                        <a href={card.link} className="card-link">
                            Толығырақ
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ProjectsContent;