import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ProjectsContent from "../components/ProjectsContent";

const ProjectsScreen = () => {
    return (
        <div>
            <NavBar />
            <main>
                <ProjectsContent />
            </main>
            <Footer />
        </div>
    );
};

export default ProjectsScreen;