import React, { useState } from "react";
import "../styles/Header.css";
import logo from '../logo.svg';
import { useNavigate } from 'react-router-dom';
import menu_icon from '../assets/icons/menu.svg';
import close_icon from '../assets/icons/close.svg';
import RightNav from './RightNav';

const Header = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const navLinks = [
        { path: "/about", label: "Біз туралы" },
        { path: "/news", label: "Жаңалықтар" },
        { path: "/values", label: "Құндылықтар" },
        { path: "/projects", label: "Жобалар" },
        { path: "/contacts", label: "Контактілер" },
    ];

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo">
                    <img onClick={() => navigate('/')} src={logo} alt="Отбасы Хрестоматиясы" />
                </div>
                <nav className={`nav ${isMenuOpen ? "nav-open" : ""}`}>
                    {navLinks.map((link, index) => (
                        <a key={index} href={link.path}>
                            {link.label}
                        </a>
                    ))}
                </nav>
                <button onClick={() => navigate('/books')} className="button header_books_button">
                    Кітаптар
                </button>
                <button onClick={handleMenuToggle} className="nav_button">
                    <img src={isMenuOpen ? close_icon : menu_icon} alt="Menu Icon" />
                </button>
            </div>
            {isMenuOpen && <RightNav onClose={handleMenuToggle} />}
            <section className="hero">
                <div className="hero-content">
                    <h1>
                        Отбасы хрестоматиясы <span className="header-text-net">қоғамдық қоры</span>
                    </h1>
                    <p>
                        Даналық пен даралықтың насихаты<br />
                        Мән іздеген жандарға арналған<br />
                        Экзистенциялық әдебиеттер<br />
                        Құндылықтар қоймасы
                    </p>
                    <button className="hero-button">Жобалар</button>
                </div>
                <div className="news">
                    <h2>Соңғы жаңалықтар</h2>
                    <div className="news-items">
                        <div className="news-item">
                            <span className="news-icon">🔗</span>
                            <p>“Ғылымға жол” қайырымдылық акциясы</p>
                        </div>
                        <div className="news-item">
                            <span className="news-icon">📘</span>
                            <p>“Менің сүйікті кітабым” кітап оқу марафоны ашық</p>
                        </div>
                        <div className="news-item">
                            <span className="news-icon">📖</span>
                            <p>
                                Алматы қаласы жастар саясатының қолдауымен жастар арасында QUIZ өтті
                            </p>
                        </div>
                        <div className="news-item">
                            <span className="news-icon">📚</span>
                            <p>
                                Астана қаласында “Алаш заңгерлері” кітабының тұсауы кесілді
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    );
};

export default Header;