import React from "react";
import "../styles/AboutSection.css";

import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';

const AboutSection = () => {
    return (
        <section className="about-section">
            <div className="about-content">
                <h2>Біз туралы</h2>
                <p>
                    2013 жылы Отбасы хрестоматиясы сериясымен шыққан алғашқы кітап “Әлдиден эпосқа дейін” жарық көрді.
                    2014-16 жылдары осы жұмыстың жалғасы ретінде “Салт-дәстүр сөйлейді”, “Сияр-Шәріп” кітаптары жарияланды.
                    Бұл еңбектер оқырманның көңілінен шығып, әрқайсысы 5-6 реттен қайта басылды. 2017 жылы «Отбасы хрестоматиясы» жобасы
                    қоғамдық қор ретінде құрылды. Кейіннен осы сериямен “Логотерапия немесе өмірге ғашық болу”, “Яссауи феномені”,
                    “Өмірге ғашық болу”, “Махаббаттың мәні”, “Өркендеген адам”, “Әділ патша”, “Мың сұрақ”,
                    “Экософия немесе киелі табиғат” сынды кітаптарды жарыққа шығарды. Кітаптардың жалпы тиражы 150 мыңнан асады.
                </p>
                <button className="button">Толығырақ</button>
            </div>
            <div className="about-images">
                <img src={image4} alt="Team Activity" />
                <img src={image3} alt="Team Activity" />
                <img src={image1} alt="Team Activity" />
                <img src={image2} alt="Team Activity" />
            </div>
        </section>
    );
};

export default AboutSection;