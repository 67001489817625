import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ValuesContent from "../components/ValuesContent";

const ValuesScreen = () => {
    return (
        <div>
            <NavBar />
            <main>
                <ValuesContent />
            </main>
            <Footer />
        </div>
    );
};

export default ValuesScreen;