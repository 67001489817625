import React from 'react';
import '../styles/RightNav.css';

function RightNav() {
    return (
        <div className="right-nav-content">
            <nav className="nave">
                <a data-aos="fade-left" className="right-nav-button" href="/about">Біз туралы</a>
                <a data-aos="fade-left" className="right-nav-button" href="/news">Жаңалықтар</a>
                <a data-aos="fade-left" className="right-nav-button" href="/books">Кітаптар</a>
                <a data-aos="fade-left" className="right-nav-button" href="/values">Құндылықтар</a>
                <a data-aos="fade-left" className="right-nav-button" href="/projects">Жобалар</a>
                <a data-aos="fade-left" className="right-nav-button" href="/contact">Контактар</a>
            </nav>
        </div>
    )
}

export default RightNav