import React, { useState } from "react";
import "../styles/BooksSection.css";
import books from "../assets/data/books.json";

const BooksSection = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    const handleMoreInfoClick = (card) => {
        setSelectedCard(card);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedCard(null);
    };

    return (
        <section className="projects-section">
            <div className="cards-container">
                {books.map((card, index) => (
                    <div
                        style={{ backgroundImage: `url(${card.image})` }}
                        className="books_card"
                        key={index}
                    >
                        <div className="books_card_content">
                            <h3>{card.title}</h3>
                            <div className="books_card_button">
                                <button
                                    onClick={() => handleMoreInfoClick(card)}
                                    className="button"
                                >
                                    Толығырақ
                                </button>
                                <a target="_blank" href={card.link} className="button">
                                    Сатып алу
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {isModalOpen && selectedCard && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{selectedCard.title}</h2>
                        <p>{selectedCard.description}</p>
                        <div className="authors-section">
                            <h3>Авторлар:</h3>
                            <div className="authors-list">
                                {selectedCard.authors.map((author, index) => (
                                    <div className="team-card" key={index}>
                                        <div className="team-image">
                                            <img src={author.image} alt={author.name} />
                                        </div>
                                        <div className="team-info">
                                            <h3>{author.name}</h3>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button onClick={closeModal} className="close-button">
                            Жабу
                        </button>
                    </div>
                </div>
            )}
        </section>
    );
};

export default BooksSection;